export const NETWORK_ERROR = "ERR_NETWORK";
export const RETRY_MAX_COUNT = 3;
export const RETRY_DELAY = 500;

export const ErrorTexts = {
  Login401: {
    title: "ログインエラー",
    contents: (id?: string) => {
      return (
        // eslint-disable-next-line react/react-in-jsx-scope
        <>
          {/* eslint-disable-next-line react/react-in-jsx-scope */}
          <p>
            {"メールアドレスとパスワードが"}
            {/* eslint-disable-next-line react/react-in-jsx-scope */}
            <br />
            {"正しいか再度ご確認ください。"}
          </p>
          {/* eslint-disable-next-line react/react-in-jsx-scope */}
          <p style={{ color: "#666666", fontSize: "14px" }}>{"401"}</p>
          {/* eslint-disable-next-line react/react-in-jsx-scope */}
          {id && <p style={{ color: "#999999", fontSize: "12px" }}>{id}</p>}
        </>
      );
    },
  },
  Error400: {
    title: "エラー",
    contents: (id?: string) => {
      return (
        // eslint-disable-next-line react/react-in-jsx-scope
        <>
          {/* eslint-disable-next-line react/react-in-jsx-scope */}
          <p>
            {"エラーが発生しました。"}
            {/* eslint-disable-next-line react/react-in-jsx-scope */}
            <br />
            {"入力した内容を再度確認してください。"}
            {/* eslint-disable-next-line react/react-in-jsx-scope */}
          </p>
          {/* eslint-disable-next-line react/react-in-jsx-scope */}
          <p style={{ color: "#666666", fontSize: "14px" }}>{"400"}</p>
          {/* eslint-disable-next-line react/react-in-jsx-scope */}
          {id && <p style={{ color: "#999999", fontSize: "12px" }}>{id}</p>}
        </>
      );
    },
  },
  Error400Token: {
    title: "エラー",
    contents: (id?: string) => {
      return (
        // eslint-disable-next-line react/react-in-jsx-scope
        <>
          {/* eslint-disable-next-line react/react-in-jsx-scope */}
          <p>
            {"無効なトークンです。"}
            {/* eslint-disable-next-line react/react-in-jsx-scope */}
            <br />
          </p>
          {/* eslint-disable-next-line react/react-in-jsx-scope */}
          <p style={{ color: "#666666", fontSize: "14px" }}>{"400"}</p>
          {/* eslint-disable-next-line react/react-in-jsx-scope */}
          {id && <p style={{ color: "#999999", fontSize: "12px" }}>{id}</p>}
        </>
      );
    },
  },
  Error404: {
    title: "エラー",
    contents: (id?: string) => {
      return (
        // eslint-disable-next-line react/react-in-jsx-scope
        <>
          {/* eslint-disable-next-line react/react-in-jsx-scope */}
          <p>
            {"エラーが発生しました。"}
            {/* eslint-disable-next-line react/react-in-jsx-scope */}
            <br />
            {"時間を置いてから再度アクセスしてください"}
            {/* eslint-disable-next-line react/react-in-jsx-scope */}
          </p>
          {/* eslint-disable-next-line react/react-in-jsx-scope */}
          <p style={{ color: "#666666", fontSize: "14px" }}>{"404"}</p>
          {/* eslint-disable-next-line react/react-in-jsx-scope */}
          {id && <p style={{ color: "#999999", fontSize: "12px" }}>{id}</p>}
        </>
      );
    },
  },
  Error500: {
    title: "エラー",
    contents: (id?: string) => {
      return (
        // eslint-disable-next-line react/react-in-jsx-scope
        <>
          {/* eslint-disable-next-line react/react-in-jsx-scope */}
          <p>
            {"エラーが発生しました。"}
            {/* eslint-disable-next-line react/react-in-jsx-scope */}
            <br />
            {"時間を置いてから再度アクセスしてください"}
            {/* eslint-disable-next-line react/react-in-jsx-scope */}
          </p>
          {/* eslint-disable-next-line react/react-in-jsx-scope */}
          <p style={{ color: "#666666", fontSize: "14px" }}>{"500"}</p>
          {/* eslint-disable-next-line react/react-in-jsx-scope */}
          {id && <p style={{ color: "#999999", fontSize: "12px" }}>{id}</p>}
        </>
      );
    },
  },
  PasswordForgetError: {
    title: "エラー",
    contents: (id?: string, status?: number) => {
      return (
        // eslint-disable-next-line react/react-in-jsx-scope
        <>
          {/* eslint-disable-next-line react/react-in-jsx-scope */}
          <p>
            {"エラーが発生しました。"}
            {/* eslint-disable-next-line react/react-in-jsx-scope */}
            <br />
            {"時間を置いてから再度アクセスしてください"}
          </p>
          {/* eslint-disable-next-line react/react-in-jsx-scope */}
          <p style={{ color: "#666666", fontSize: "14px" }}>
            {status ? status : ""}
          </p>
          {/* eslint-disable-next-line react/react-in-jsx-scope */}
          {id && (
            // eslint-disable-next-line react/react-in-jsx-scope
            <p style={{ color: "#999999", fontSize: "12px" }}>{id ? id : ""}</p>
          )}
        </>
      );
    },
  },
  NetworkError: {
    title: "ネットワークラー",
    contents: "時間を置いてから再度アクセスしてください",
  },
};
